import { useResource } from '@/lib/datx-jsonapi-react';
import { queryUserSession } from '@/queries/sessions';
import { SESSION_ME } from '@/utils/constants';

export default function useSession() {
  const swr = useResource(queryUserSession(SESSION_ME), {
    shouldRetryOnError: (err) => {
      return err.status !== 401;
    },
  });

  return {
    get session() {
      return swr.data;
    },
    get sessionError() {
      return swr.error;
    },
    get isSessionValidating() {
      return swr.isValidating;
    },
    get user() {
      return swr.data?.user;
    },
    get thinProfile() {
      return swr.data?.mainProfile;
    },
    get isSessionLoading() {
      return !swr.data && !swr.error;
    },
    mutateSession: swr.mutate,
  };
}
