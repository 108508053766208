import styled from '@emotion/styled';
import { isPast, isFuture } from 'date-fns';
import { FC, useMemo, Fragment, useCallback } from 'react';
import { useLocalStorage } from 'react-use';

import { FontFamily } from '@/enums/index';
import { Banner } from '@/resources/Banner';
import { IThemeProps } from '@/styles/themes/types';

import { CloseButton } from './core/CloseButton';
import { Container } from './core/Grid';
import { Markdown } from './Markdown';

const STORAGE_KEY = 'underline-banners';

const Wrapper = styled('div')`
  background: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 100;
  padding: 16px 0;
`;

const AlertContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface IAlertStatusProps extends IThemeProps {
  type: string;
}

const AlertStatus = styled.div<IAlertStatusProps>`
  min-width: 16px;
  height: 16px;
  border-radius: 50%;
  background: ${({ theme, type }) => theme.legacy.colors.status[type]};
`;

const MessageContainer = styled(Markdown)<IThemeProps>`
  font-size: 16px;
  line-height: 24px;
  font-family: ${({ theme }) => theme.legacy.typography.fontFamily[FontFamily.IBMPlexSans]};
  padding: 0 30px;
`;

const getInitialData = (banners: Array<Banner>) => {
  const result: { [id: string]: boolean } = {};

  banners.forEach(({ id }) => {
    result[id] = true;
  });

  return result;
};

interface ILiveStreamAlertProps {
  banners: Array<Banner>;
}

/**
 * Show banners sorted by its content (ones with more content go to the top)
 */
export const AdminAlerts: FC<ILiveStreamAlertProps> = ({ banners }) => {
  const [bannersStorage, setBannersStorage] = useLocalStorage(STORAGE_KEY, getInitialData(banners));

  const showBanners = useMemo(
    () =>
      banners.map((banner) => {
        if (isPast(new Date(banner.shownUntil)) || isFuture(new Date(banner.shownAt))) {
          return false;
        }

        if (bannersStorage[banner.id] !== undefined) {
          return bannersStorage[banner.id];
        }

        setBannersStorage((prevBannersStorage) => ({ ...prevBannersStorage, [banner.id]: true }));

        return true;
      }),
    [setBannersStorage, bannersStorage, banners]
  );

  const handleCloseBanner = useCallback(
    (id: string) => () => setBannersStorage((prevBannersStorage) => ({ ...prevBannersStorage, [id]: false })),
    [setBannersStorage]
  );

  if (!showBanners) {
    return null;
  }

  return (
    <Fragment>
      {banners
        .filter(({ id }) => bannersStorage[id])
        .map(({ type, content, id }) => (
          <Wrapper css={{ zIndex: 100 + content.length }} key={id}>
            <AlertContainer>
              <AlertStatus type={type} />
              <MessageContainer>{content}</MessageContainer>
              <CloseButton variant="dark" size="small" onClose={handleCloseBanner(id)} />
            </AlertContainer>
          </Wrapper>
        ))}
    </Fragment>
  );
};
