import { useResource } from 'datx-jsonapi-react';
import { useEffect, FC } from 'react';

import { queryLiblynxAuthorizationGrants } from '@/queries/liblynx-authorization-grants';

export const defaultLLProps = {
  pid: process.env.NEXT_PUBLIC_LL_PID,
  aname: null,
  url: process.env.NEXT_PUBLIC_BASE_URL,
  aid: null,
};

export const LibLynxCounterDefaults: FC = () => {
  const { data } = useResource(queryLiblynxAuthorizationGrants('auth_grant'));

  useEffect(() => {
    if (!data) return;
    window._ll.push([
      'setDefaults',
      {
        ...defaultLLProps,
        aid: data.liblynxAccountId ? String(data.liblynxAccountId) : 'anon',
        aname: data.liblynxOrganization,
      },
    ]);
  }, [data]);

  return null;
};
