import { format } from 'date-fns';

/**
 * Method for parsing custom markdown syntax for timestamps: `<timestamp>ISO-string</timestamp>`.
 *
 * Converts `ISO-string` date format to `hh:mm` time format
 *
 * @param source stringified markdown source
 * @returns parsed markdown source
 */
export const convertTimestampsToLocalizedTime = (source: string) => {
  return source?.replace(/<timestamp>(.+)<\/timestamp>/g, (_, timestamp) => {
    return format(new Date(timestamp), 'hh:mm aa');
  });
};
