import { useRouter } from 'next/router';

const useModalName = () => {
  const router = useRouter();

  const modalName = (router.query.modal as string) || '';

  return modalName;
};

export default useModalName;
