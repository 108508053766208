import { useAsync } from 'react-use';

import { Banner } from '@/resources/Banner';

import { useStores } from './useStores';

/**
 * A hook to get you banners from API
 */
export const useBanners = () => {
  const { store } = useStores();

  const { value } = useAsync(async () => {
    const bannersResponse = await store.fetchAll(Banner, {
      sort: '-shown_at',
    });

    return bannersResponse.data as Array<Banner>;
  });

  return value;
};
