import { FC, ReactNode, useContext, useEffect } from 'react';

import { FadeInModalContext } from '@/components/layouts/fadeInModalContext';

interface IFadeInModalProps {
  children: ReactNode;
  showModal: boolean;
}

export const FadeInModal: FC<IFadeInModalProps> = ({ showModal, children }) => {
  const context = useContext(FadeInModalContext);

  const activeModalName = context.showModalType.get;

  useEffect(() => {
    if (activeModalName) {
      document.body.classList.add('modalVisible');
    } else {
      document.body.classList.remove('modalVisible');
    }
  }, [activeModalName]);

  return showModal ? <div>{children}</div> : null;
};
