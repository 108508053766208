import styled from '@emotion/styled';

import { FontFamily } from '@/enums/index';
import { BREAKPOINTS } from '@/styles/themes/default';
import { IThemeProps } from '@/styles/themes/types';

interface IHeaderProps extends IThemeProps {
  as?: string;
}

export const H1 = styled('h1')<IHeaderProps>`
  font-family: ${({ theme }) => theme.legacy.typography.fontFamily[FontFamily.SpaceGrotesk]};
  font-size: 40px;
  line-height: 48px;

  @media (min-width: ${BREAKPOINTS.Desktop.min}px) {
    font-size: 64px;
    line-height: 90px;
  }
`;

export const H2 = styled('h2')<IHeaderProps>`
  font-family: ${({ theme }) => theme.legacy.typography.fontFamily[FontFamily.SpaceGrotesk]};
  font-size: 32px;
  line-height: 48px;

  @media (min-width: ${BREAKPOINTS.Desktop.min}px) {
    font-size: 48px;
    line-height: 56px;
  }
`;

export const H3 = styled('h3')<IHeaderProps>`
  font-family: ${({ theme }) => theme.legacy.typography.fontFamily[FontFamily.SpaceGrotesk]};
  font-size: 24px;
  line-height: 32px;

  @media (min-width: ${BREAKPOINTS.Desktop.min}px) {
    font-size: 32px;
    line-height: 45px;
  }
`;

export const H4 = styled('h4')<IHeaderProps>`
  font-family: ${({ theme }) => theme.legacy.typography.fontFamily[FontFamily.SpaceGrotesk]};
  font-size: 20px;
  line-height: 24px;

  @media (min-width: ${BREAKPOINTS.Desktop.min}px) {
    font-size: 24px;
    line-height: 34px;
  }
`;
