import { createContext } from 'react';

import { OverlayType } from '@/enums/index';

interface IFadeInModalContext {
  showModalType: { get: OverlayType | string; set: (type: OverlayType) => void };
}

export const FadeInModalContext = createContext<IFadeInModalContext>({
  showModalType: null,
});
