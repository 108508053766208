import {
  Box,
  chakra,
  forwardRef,
  HStack,
  HTMLChakraProps,
  Link,
  LinkProps,
  SystemStyleObject,
  useMediaQuery,
  useMergeRefs,
  useSafeLayoutEffect,
  useTheme,
} from '@chakra-ui/react';
import { createContext } from '@chakra-ui/react-utils';
import { focus } from '@chakra-ui/utils';
import { FC, ReactNode, useRef, useState } from 'react';
import FocusLock from 'react-focus-lock';
import { InView } from 'react-intersection-observer';
import { RemoveScroll } from 'react-remove-scroll';
import { useKey } from 'react-use';

import Close from '@/assets/icons/ic-close.svg';
import Hamburger from '@/assets/icons/ic-hamburger.svg';

import { isServer } from '@/utils/env';

const ChakraHeader = chakra('header', {
  baseStyle: {
    position: 'sticky',
    // Combined with the Intersection Observer, it gives us a way
    // to change the background as soon as the user starts scrolling
    top: '-1px',
    display: 'flex',
    alignItems: 'center',
    // Gives default padding and then centers the element on large screens
    // Check: https://codepen.io/filipjakov/pen/dyOmBJp
    px: { base: 5, lg: 'max(2.5rem, 50vw - (1200px / 2))' },
    transition: 'background .2s ease-in',
    zIndex: 'sticky',
    color: 'white',
    minH: '64px',
    '&': {
      // There seems to be a problem on how the sticky position gets polyfilled
      position: '-webkit-sticky',
    },
  },
});

export const ChakraNav = chakra('nav', {
  baseStyle: {
    position: { base: 'fixed', lg: 'static' },
    top: '0',
    left: '0',
    display: 'flex',
    flexDirection: { base: 'column', lg: 'row' },
    justifyContent: { lg: 'flex-end' },
    flexGrow: 1,
    height: { base: '100%', lg: 'initial' },
    minW: { base: 'min(100%, 375px)', lg: '24rem' },
    overflowY: { base: 'auto', lg: 'initial' },
    bgColor: { base: 'black', lg: 'initial' },
    color: { base: 'white', lg: 'inherit' },
    // Drawer Animation
    visibility: { base: 'hidden', lg: 'visible' },
    transform: { base: 'translateX(-110vw)', lg: 'initial' },
    willChange: 'transform',
    transition: { base: 'transform .3s ease, visibility 0s linear .3s', lg: 'initial' },
    '&:target': {
      visibility: 'visible',
      transform: 'translateX(0)',
      transition: 'transform .3s ease',
    },
  },
});

interface ICoreNavigationContext {
  closeRef: React.RefObject<HTMLAnchorElement>;
  openRef: React.RefObject<HTMLAnchorElement>;
  isNavOpen: boolean;
  setIsNavOpen: (isOpen: boolean) => void;
}

const [CoreNavigationProvider, useCoreNavigationContext] = createContext<ICoreNavigationContext>();

const getVariantStyles = (variant: ICoreNavigationProps['variant']) => {
  const variants: Record<ICoreNavigationProps['variant'], SystemStyleObject> = {
    default: {
      bg: 'black',
    },
    overlay: {
      bg: 'gradientNavigation',
    },
    ghost: {
      bg: 'black',
      '&[data-in-view="true"]': {
        bg: 'transparent',
      },
    },
    inverted: {
      bg: 'black',
      color: 'white',
      '&[data-in-view="true"]': {
        bg: 'transparent',
        color: 'black',
      },
    },
    transparent: {
      '&[data-in-view]': {
        bg: 'transparent',
        color: 'black',
      },
    },
  };

  return variants[variant];
};

export interface ICoreNavigationProps {
  variant: 'default' | 'overlay' | 'ghost' | 'inverted' | 'transparent';
  children: ReactNode;
}

export const CoreNavigation: FC<ICoreNavigationProps> = ({ children, variant, ...rest }) => {
  const closeRef = useRef<HTMLAnchorElement | null>(null);
  const openRef = useRef<HTMLAnchorElement | null>(null);

  const [isNavOpen, setIsNavOpen] = useState(() => !isServer && document.location.hash === '#nav');

  useKey('Escape', () => (document.location.hash = ''));

  useSafeLayoutEffect(() => {
    const onHashChange = () => setIsNavOpen(document.location.hash === '#nav');

    window.addEventListener('hashchange', onHashChange);

    return () => window.removeEventListener('hashchange', onHashChange);
  }, []);

  const styles = getVariantStyles(variant);

  return (
    <CoreNavigationProvider value={{ closeRef, openRef, isNavOpen, setIsNavOpen }}>
      <InView threshold={[1]}>
        {({ inView, ref }) => (
          <ChakraHeader ref={ref} data-in-view={inView} __css={styles} {...rest}>
            {children}
          </ChakraHeader>
        )}
      </InView>
    </CoreNavigationProvider>
  );
};

export type ICoreNavigationHamburgerProps = LinkProps;

export const CoreNavigationHamburger = forwardRef<ICoreNavigationHamburgerProps, 'a'>(function CoreNavigationHamburger(
  { ...rest },
  ref
) {
  const { openRef } = useCoreNavigationContext();

  const refs = useMergeRefs(ref, openRef);

  return (
    <Link ref={refs} href="#nav" position="absolute" display={{ lg: 'none' }} p="5" ml={{ base: -5, md: 0 }} {...rest}>
      <Hamburger css={{ height: '1.5rem' }} />
    </Link>
  );
});

export type ICoreNavigationBrandProps = HTMLChakraProps<'div'>;

export const CoreNavigationBrand = forwardRef<ICoreNavigationBrandProps, 'div'>(function CoreNavigationBrand(
  { children, ...rest },
  ref
) {
  return (
    <HStack ref={ref} h="17px" mx={{ base: 'auto', lg: 'initial' }} minW={0} {...rest}>
      {children}
    </HStack>
  );
});

export interface ICoreNavigationNavProps extends HTMLChakraProps<'nav'> {
  children?: ReactNode;
}

export const CoreNavigationNav = forwardRef<ICoreNavigationNavProps, 'div'>(function CoreNavigationContainer(
  { children, ...rest },
  ref
) {
  const { breakpoints } = useTheme();
  const { closeRef, isNavOpen } = useCoreNavigationContext();

  // Side navigation (drawer) should be shown only on devices smaller than desktop
  const [shouldSideNavBeShown] = useMediaQuery(`(max-width: ${breakpoints.lg})`);

  return (
    <RemoveScroll enabled={shouldSideNavBeShown && isNavOpen} forwardProps>
      <Box ref={ref} flexBasis="auto" flexShrink={0} className="scroll" {...rest}>
        <FocusLock
          as={ChakraNav}
          className="scroll"
          disabled={!shouldSideNavBeShown || !isNavOpen}
          lockProps={{ id: 'nav' }}
          onActivation={() => focus(closeRef.current)}
        >
          {children}
        </FocusLock>
      </Box>
    </RemoveScroll>
  );
});

export type ICoreNavigationCloseProps = LinkProps;

export const CoreNavigationClose = forwardRef<ICoreNavigationCloseProps, 'a'>(function CoreNavigationClose(
  { onClick, ...rest },
  ref
) {
  const { closeRef, openRef } = useCoreNavigationContext();
  const refs = useMergeRefs(ref, closeRef);

  return (
    <Link
      ref={refs}
      href="#_"
      display={{ lg: 'none' }}
      width="min-content"
      ml={{ md: 5 }}
      p="5"
      onClick={(...args) => {
        focus(openRef.current);
        onClick?.(...args);
      }}
      {...rest}
    >
      <Close css={{ width: '1.5rem' }} />
    </Link>
  );
});

export const CoreNavigationActions = chakra('div', {
  baseStyle: {
    display: 'flex',
    flexDirection: { base: 'column', lg: 'row' },
    alignItems: { base: 'start', lg: 'center' },
    flexWrap: 'wrap',
    mt: { base: 5, lg: 0 },
    pt: { base: 5, lg: 0 },
    borderTopWidth: { base: '1px', lg: 0 },
    borderTopStyle: 'solid',
    borderTopColor: 'gray.800',
    position: 'relative',
  },
});
