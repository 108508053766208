import { forwardRef, Link, LinkProps } from '@chakra-ui/react';

type Booleanish = boolean | 'true' | 'false';

interface INavLinkProps extends LinkProps {
  isActiveLink?: boolean;
}

const ariaCurrentPageAttr = (condition: boolean | undefined) => (condition ? 'page' : undefined) as Booleanish;

export const NavLink = forwardRef<INavLinkProps, 'a'>(({ isActiveLink, ...rest }, ref) => {
  return (
    <Link
      aria-current={ariaCurrentPageAttr(isActiveLink)}
      py={3}
      px={{ base: 5, sm: null, md: 10, lg: 3, xl: 5 }}
      fontSize="md"
      lineHeight="shorter"
      fontWeight="bold"
      _activeLink={{ textDecoration: 'underline' }}
      {...rest}
      ref={ref}
    />
  );
});
